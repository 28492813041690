import classNames from 'classnames'
import VerifiedBadgeIcon from 'shared/icons/VerifiedBadgeIcon'
import { getEllipsisText } from 'utils/string'

type Props = {
  fullName: string | null // some users have fullName is null
  isVerified: boolean
  className?: string
  maxNameLength?: number // pass a negative number to disable ellipsis
}

const NameWithVerificationBadge = (props: Props) => {
  const { fullName, isVerified, className, maxNameLength = 12 } = props
  const name = getEllipsisText(fullName || '', maxNameLength)
  const isTruncatedWithin1Line = className?.includes('line-clamp-1')

  const VerifiedBadge = (
    <>
      {/* placeholder for the icon */}
      <VerifiedBadgeIcon className="invisible size-[1em]" />
      {/* the display icon */}
      <VerifiedBadgeIcon className="absolute right-0 top-1/2 inline size-[1em] -translate-y-1/2" />
    </>
  )
  if (isTruncatedWithin1Line) {
    return (
      <p className={classNames(className, 'relative pr-1')}>
        {name}
        {isVerified && VerifiedBadge}
      </p>
    )
  }
  return (
    <p className={classNames(className, 'pr-1')}>
      {name.slice(0, name.length - 1)}
      <span className="relative whitespace-nowrap">
        {/* this prevents the icon being push to next line */}
        {name.slice(name.length - 1, name.length)}&nbsp;
        {isVerified && VerifiedBadge}
      </span>
    </p>
  )
}

export default NameWithVerificationBadge
