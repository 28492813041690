import { apiClient } from 'utils/apiClient'
import { withApiErrorHandled } from 'utils/common'

const confirmInvitation = async (invitationToken: string) => {
  try {
    await withApiErrorHandled(apiClient.post)('/v2/onboarding/invitations/confirm', { invitationToken })
  } catch (error) {
    console.error(error)
    console.error('Cannot confirm invitation')
    // we don't throw error here, because we don't want to block the user from signing up if the invitation is invalid, or something went wrong
  }
}

export default confirmInvitation
