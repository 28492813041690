import cn from 'classnames'

const VerifiedBadgeIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('inline h-[1em] w-[1em]', className)}
    >
      <path
        d="M8.70294 14.6182C8.53229 14.4428 8.25059 14.4428 8.07987 14.6181L6.78926 15.9433C6.55209 16.1868 6.13976 16.0762 6.0563 15.7467L5.60251 13.9549C5.54242 13.7177 5.29845 13.5768 5.06292 13.6432L3.28149 14.1461C2.95428 14.2385 2.65243 13.9364 2.74502 13.6093L3.24875 11.8294C3.31541 11.5939 3.17458 11.3498 2.93731 11.2896L1.14385 10.8346C0.814358 10.751 0.703923 10.3387 0.947541 10.1016L2.27243 8.81232C2.4478 8.64167 2.44785 8.35997 2.27255 8.18924L0.947419 6.89872C0.703875 6.66153 0.814512 6.24917 1.14407 6.16575L2.93709 5.7119C3.17445 5.65182 3.31541 5.40767 3.24877 5.17207L2.74501 3.39109C2.65245 3.06385 2.95448 2.76182 3.28171 2.85438L5.0627 3.35814C5.2983 3.42479 5.54244 3.28383 5.60252 3.04646L6.05637 1.25345C6.13979 0.923886 6.55216 0.813249 6.78934 1.05679L8.07987 2.38193C8.25059 2.55723 8.53229 2.55717 8.70294 2.3818L9.99222 1.05692C10.2293 0.813298 10.6417 0.923733 10.7252 1.25322L11.1802 3.04669C11.2404 3.28396 11.4845 3.42479 11.7201 3.35813L13.4999 2.8544C13.8271 2.76181 14.1291 3.06365 14.0367 3.39087L13.5339 5.17229C13.4674 5.40782 13.6083 5.6518 13.8455 5.71188L15.6373 6.16568C15.9668 6.24914 16.0774 6.66146 15.8339 6.89863L14.5087 8.18924C14.3334 8.35997 14.3335 8.64167 14.5088 8.81232L15.8338 10.1017C16.0774 10.3387 15.967 10.7511 15.6375 10.8347L13.8453 11.2896C13.6082 11.3498 13.4674 11.5938 13.5339 11.8292L14.0367 13.6095C14.1291 13.9366 13.8273 14.2385 13.5001 14.1461L11.7198 13.6433C11.4844 13.5768 11.2404 13.7176 11.1802 13.9547L10.7253 15.7469C10.6417 16.0764 10.2294 16.1868 9.9923 15.9432L8.70294 14.6182Z"
        fill="#00D973"
      />
      <path
        d="M5.60938 9.19633L7.69633 11.2833L11.1746 7.10938"
        stroke="white"
        strokeWidth="0.869565"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default VerifiedBadgeIcon
