import cn from 'classnames'
import CustomImage, { CustomImageProps } from 'shared/components/CustomImage'

type RoundedAvatarImageProps = CustomImageProps & {
  zIndex?: number
  onClick?: () => void
  customFallbackImageUrl?: string
}

const RoundedAvatarImage = (props: RoundedAvatarImageProps) => {
  const { className, src, alt, zIndex, sizes = '256px', quality = 80, onClick, customFallbackImageUrl } = props
  const displayPhoto = src || customFallbackImageUrl || '/icons/default-ava.svg'

  return (
    <span className={cn('relative box-content inline-block overflow-hidden rounded-full', className)}>
      <CustomImage
        {...props}
        src={displayPhoto}
        blurDataURL="/icons/default-ava-2.svg"
        placeholder="blur"
        alt={alt || 'user avatar'}
        fill
        className={cn('rounded-full object-cover object-center', src && 'outline outline-1 outline-black/10')}
        style={{ zIndex }}
        fallbackSrc="/images/default.png"
        sizes={sizes}
        quality={quality}
        onClick={onClick}
      />
    </span>
  )
}

export default RoundedAvatarImage
